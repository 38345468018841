import React from "react";
import NavbarMenu from "../components/Navbar";


const Home = () => {
  document.title = "Home";

  return (
    <>
      <NavbarMenu />

    </>
  );
};

export default Home;
